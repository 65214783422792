import { EddyTravels } from 'components/icons/EddyTravels';
import React, { ReactNode, ReactText } from 'react';
import styled from 'styled-components';

const LayoutWrap = styled.div<{ overflowY: 'hidden' | 'scroll' | 'auto' }>((props) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: props.overflowY,
}));

const PageContainer = styled.div({
    width: '330px',
    margin: '0 auto',
    textAlign: 'center',
});

const PageContent = styled.div((props) => ({
    paddingTop: '30px',
    color: props.theme.colors.darkgrey,
    lineHeight: '24px',
}));

const ImageContainer = styled.div<{ headerImageHeight?: string }>((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.headerImageHeight || '150px',
}));

const PageTitleWrapper = styled.div({
    position: 'relative',
    display: 'inline-block',
});

const Underline = styled.div((props) => ({
    position: 'absolute',
    borderTop: `7px solid ${props.theme.colors.primary}`,
    width: '100%',
    bottom: '3px',
}));

const ExpandToLength = styled.div({
    visibility: 'hidden',
});

const OnTop = styled.div({
    position: 'absolute',
    top: 0,
});

const Title = styled.div((props) => ({
    fontFamily: props.theme.fonts.bold,
    fontWeight: 700,
    fontSize: '32px',
    color: props.theme.colors.text,
    '@media (max-width: 991px)': {
        fontSize: '24px',
    },
}));

const PageTitle = (props: { children: ReactText }) => {
    return (
        <PageTitleWrapper>
            <ExpandToLength>
                <Title>{props.children}</Title>
            </ExpandToLength>
            <Underline />
            <OnTop>
                <Title>{props.children}</Title>
            </OnTop>
        </PageTitleWrapper>
    );
};

function PageLayout(props: {
    children: ReactNode;
    headerImage?: ReactNode;
    headerImageHeight?: string;
    title?: string | string[];
    overflowY?: 'hidden' | 'scroll' | 'auto';
}) {
    let titleLines: string | string[] = [];
    if (props.title) {
        titleLines = Array.isArray(props.title) ? props.title : [props.title];
    }
    return (
        <LayoutWrap overflowY={props.overflowY || 'hidden'}>
            <PageContainer>
                <ImageContainer headerImageHeight={props.headerImageHeight}>
                    {props.headerImage || <EddyTravels />}
                </ImageContainer>
                {props.title &&
                    titleLines.map((line: string, i: number) => (
                        <div key={i}>
                            <PageTitle>{line}</PageTitle>
                        </div>
                    ))}
                <PageContent>{props.children}</PageContent>
            </PageContainer>
        </LayoutWrap>
    );
}

export default PageLayout;
