import React, { forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
    error?: string;
    touched?: boolean;
    active: boolean;
}

const TextInputWrapper = styled.input<{ borderColor: string }>((props) => ({
    appearance: 'none',
    position: 'relative',
    outline: 'none',
    width: '100%',
    height: 50,
    background: '#ffffff',
    border: `1px solid ${props.borderColor}`,
    boxSizing: 'border-box',
    borderRadius: 4,
    fontSize: 14,
    fontFamily: props.theme.fonts.default,
    lineHeight: '24px',
    color: '#231f20',
    paddingLeft: 15,
    marginBottom: 10,
}));

const TextInputError = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    color: '#ff0000',
    boxSizing: 'border-box',
}));

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    ({ id, value, onChange, onBlur, error, touched, name, placeholder, active, ...other }, ref) => {
        const theme = useTheme();
        let borderColor = '#E9EEF2';
        if (active) {
            borderColor = theme.colors.primaryActive;
        }
        if (touched && !!error) {
            borderColor = '#FF0000';
        }

        return (
            <div>
                <TextInputWrapper
                    id={id}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    // @ts-ignore
                    ref={ref}
                    placeholder={placeholder}
                    borderColor={borderColor}
                    {...other}
                />
                {touched && !!error && (
                    <TextInputError data-test="text-input-error-message">{error}</TextInputError>
                )}
            </div>
        );
    }
);
