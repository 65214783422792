import { Button } from '@chakra-ui/react';
import PageLayout from 'components/common/PageLayout';
import { TextInput } from 'components/common/TextInput';
import { EddyTravels } from 'components/icons/EddyTravels';
import { useFormik } from 'formik';
import { api } from 'lib/api';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import * as Yup from 'yup';

interface FormValues {
    email: string;
    [key: string]: any;
}

enum SubmitStatus {
    SUBMITTING,
    FAILURE,
    SUCCESS,
}

const Paragraph = styled.div((props) => ({
    paddingBottom: '10px',
    fontFamily: props.theme.fonts.default,
    fontSize: 14,
    textAlign: 'left',
    color: props.theme.colors.text,
}));

const PrimaryLink = styled.a((props) => ({
    color: props.theme.colors.primary,
    textDecoration: 'none',
}));

const LoginPage: React.FC = () => {
    const router = useRouter();
    const theme = useTheme();
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);

    const form = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async (values) => {
            try {
                await api.requestPartnerLoginLink(values.email);
                await router.push(`/login/success?email=${values.email}`);
                setSubmitStatus(SubmitStatus.SUCCESS);
            } catch (e) {
                setSubmitStatus(SubmitStatus.FAILURE);
            }
        },
        validationSchema: Yup.object<FormValues>().shape({
            email: Yup.string()
                .min(1)
                .email('⇧ Enter a valid email.')
                .required('⇧ Enter a valid email.'),
        }),
    });

    // making sure that form validation is trigger when email is valid
    // for some mobile device this does not trigger automatically
    useEffect(() => {
        if (!form.errors.email) {
            form.validateForm();
            if (form.values.email) {
                form.setFieldTouched('email', true);
            }
        }
    }, [form.values.email]);

    const formButtonDisabled =
        form.isSubmitting ||
        submitStatus === SubmitStatus.SUCCESS ||
        Object.keys(form.errors).length !== 0 ||
        Object.keys(form.touched).length === 0;

    return (
        <PageLayout overflowY="auto" title="Sign in" headerImage={<EddyTravels size={70} />}>
            <form onSubmit={form.handleSubmit}>
                <TextInput
                    id="email"
                    name="email"
                    type="email"
                    active={true}
                    autoComplete="email"
                    touched={form.touched.email}
                    error={form.errors.email}
                    onChange={form.handleChange}
                    onPaste={form.handleBlur}
                    onBlur={form.handleBlur}
                    value={form.values.email}
                    placeholder="Email"
                    data-test="email-input-box"
                />
                {submitStatus === SubmitStatus.FAILURE && (
                    <Paragraph>
                        User does not exist. Check if you have entered your email correctly or{' '}
                        <Link href="https://eddyai.com/" passHref>
                            <PrimaryLink>signup here</PrimaryLink>
                        </Link>
                        .
                    </Paragraph>
                )}
                <Button
                    disabled={formButtonDisabled}
                    onClick={form.submitForm}
                    _hover={formButtonDisabled ? {} : { background: theme.colors?.primaryActive }}
                    background={formButtonDisabled ? theme.colors?.grey : theme.colors?.primary}
                    color={formButtonDisabled ? theme.colors.darkgrey : theme.colors.background}
                    fontWeight="400"
                    width="100%"
                    height="50px"
                >
                    Continue
                </Button>
            </form>
        </PageLayout>
    );
};

export default LoginPage;
