export const EddyTravels = (props: { size?: number }) => {
    const size = props.size || 70;
    return (
        <svg
            width={size * 2}
            height={size}
            viewBox="0 0 169 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.85932 42.3187C1.19823 42.3187 0.716181 42.181 0.413181 41.9056C0.137727 41.6026 0 41.1481 0 40.5421V16.0404C0 15.4344 0.137727 14.9799 0.413181 14.6769C0.716181 14.3739 1.19823 14.2224 1.85932 14.2224H19.0063C19.6674 14.2224 20.1219 14.3601 20.3698 14.6356C20.6177 14.8835 20.7417 15.3242 20.7417 15.9578V17.4865C20.7417 18.1201 20.6177 18.5746 20.3698 18.85C20.1219 19.1255 19.6674 19.2632 19.0063 19.2632H6.81749V25.7915H15.329C15.9901 25.7915 16.4446 25.9292 16.6925 26.2047C16.9404 26.4526 17.0644 26.8933 17.0644 27.5268V28.973C17.0644 29.6065 16.9404 30.061 16.6925 30.3365C16.4446 30.6119 15.9901 30.7497 15.329 30.7497H6.81749V37.3192H18.965C19.6261 37.3192 20.0806 37.457 20.3285 37.7324C20.5764 38.0079 20.7004 38.4624 20.7004 39.0959V40.5834C20.7004 41.2169 20.5764 41.6714 20.3285 41.9469C20.0806 42.1948 19.6261 42.3187 18.965 42.3187H1.85932Z"
                fill="black"
            />
            <path
                d="M25.585 42.3187C24.9239 42.3187 24.4418 42.181 24.1388 41.9056C23.8634 41.6026 23.7256 41.1481 23.7256 40.5421V16.0404C23.7256 15.4344 23.8634 14.9799 24.1388 14.6769C24.4418 14.3739 24.9239 14.2224 25.585 14.2224H34.2618C38.972 14.2224 42.5529 15.3518 45.0045 17.6105C47.456 19.8692 48.6818 23.4226 48.6818 28.2706C48.6818 33.0635 47.4422 36.6031 44.9632 38.8893C42.4841 41.1756 38.9169 42.3187 34.2618 42.3187H25.585ZM34.5923 37.3192C37.0438 37.3192 38.8756 36.6444 40.0876 35.2947C41.2996 33.9174 41.9056 31.576 41.9056 28.2706C41.9056 24.9651 41.2996 22.6375 40.0876 21.2878C38.8756 19.9381 37.0438 19.2632 34.5923 19.2632H30.5431V37.3192H34.5923Z"
                fill="black"
            />
            <path
                d="M53.3052 42.3187C52.6441 42.3187 52.1621 42.181 51.8591 41.9056C51.5836 41.6026 51.4459 41.1481 51.4459 40.5421V16.0404C51.4459 15.4344 51.5836 14.9799 51.8591 14.6769C52.1621 14.3739 52.6441 14.2224 53.3052 14.2224H61.982C66.6923 14.2224 70.2732 15.3518 72.7247 17.6105C75.1763 19.8692 76.402 23.4226 76.402 28.2706C76.402 33.0635 75.1625 36.6031 72.6834 38.8893C70.2043 41.1756 66.6372 42.3187 61.982 42.3187H53.3052ZM62.3126 37.3192C64.7641 37.3192 66.5959 36.6444 67.8079 35.2947C69.0199 33.9174 69.6259 31.576 69.6259 28.2706C69.6259 24.9651 69.0199 22.6375 67.8079 21.2878C66.5959 19.9381 64.7641 19.2632 62.3126 19.2632H58.2634V37.3192H62.3126Z"
                fill="black"
            />
            <path
                d="M87.903 42.3187C87.2419 42.3187 86.7599 42.181 86.4569 41.9056C86.1814 41.6026 86.0437 41.1481 86.0437 40.5421V32.7329L76.0447 15.5859C75.907 15.3655 75.8381 15.1452 75.8381 14.9248C75.8381 14.732 75.907 14.5667 76.0447 14.429C76.21 14.2913 76.4166 14.2224 76.6645 14.2224H81.6227C82.0083 14.2224 82.2837 14.2913 82.449 14.429C82.6143 14.5392 82.7796 14.7458 82.9448 15.0488L89.4731 26.4939L96.0014 15.0488C96.1666 14.7458 96.3319 14.5392 96.4972 14.429C96.6625 14.2913 96.9241 14.2224 97.2822 14.2224H102.282C102.53 14.2224 102.722 14.2913 102.86 14.429C103.025 14.5667 103.108 14.732 103.108 14.9248C103.108 15.1452 103.039 15.3655 102.901 15.5859L92.9025 32.7743V40.5421C92.9025 41.1481 92.751 41.6026 92.448 41.9056C92.1725 42.181 91.6905 42.3187 91.0019 42.3187H87.903Z"
                fill="black"
            />
            <path
                d="M158.043 2.51758H118.081C113.686 2.51758 110.124 6.08004 110.124 10.4746V39.004V55.622C110.124 57.8002 112.382 59.2443 114.359 58.3307L124.878 53.4703C125.532 53.1681 126.244 53.0116 126.964 53.0116H158.043C162.438 53.0116 166 49.4492 166 45.0547V10.4746C166 6.08004 162.438 2.51758 158.043 2.51758Z"
                stroke="#FEB001"
                strokeWidth="5.00826"
            />
            <path
                d="M118.849 41.7614C118.353 41.7614 118.106 41.5272 118.106 41.059C118.106 40.8661 118.147 40.6458 118.23 40.3979L128.931 14.9046C129.124 14.4363 129.33 14.1195 129.551 13.9543C129.799 13.7614 130.143 13.665 130.584 13.665H133.6C134.041 13.665 134.371 13.7614 134.592 13.9543C134.812 14.1195 135.018 14.4363 135.211 14.9046L145.913 40.3979C145.995 40.6458 146.037 40.8661 146.037 41.059C146.037 41.5272 145.789 41.7614 145.293 41.7614H140.293C139.908 41.7614 139.619 41.7063 139.426 41.5961C139.26 41.4584 139.109 41.2242 138.971 40.8937L137.071 36.0181H127.03L125.171 40.8937C125.033 41.2242 124.868 41.4584 124.675 41.5961C124.51 41.7063 124.234 41.7614 123.849 41.7614H118.849ZM135.501 31.2666L132.071 22.3418L128.6 31.2666H135.501Z"
                fill="black"
            />
            <path
                d="M151.335 41.7614C150.674 41.7614 150.192 41.6236 149.889 41.3482C149.613 41.0452 149.476 40.5907 149.476 39.9847V15.483C149.476 14.877 149.613 14.4225 149.889 14.1195C150.192 13.8165 150.674 13.665 151.335 13.665H154.434C155.123 13.665 155.605 13.8165 155.88 14.1195C156.155 14.395 156.293 14.8495 156.293 15.483V39.9847C156.293 40.5907 156.155 41.0452 155.88 41.3482C155.605 41.6236 155.123 41.7614 154.434 41.7614H151.335Z"
                fill="black"
            />
        </svg>
    );
};
